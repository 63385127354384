import React from 'react'

export default function generate({
  t, classes, onShowPhotos,
}) {
  return [
    {
      name: 'itemName',
      label: t('controls-detail.item-name-title'),
    },
    {
      name: 'description',
      label: t('controls-detail.item-description-title'),
    },
    {
      name: 'photos',
      label: t('common.photo'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val.length > 0) {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img alt="vehicle" src={val[0]} className={classes.itemPhoto} onClick={() => onShowPhotos(val)} onKeyDown={() => onShowPhotos(val)} crossOrigin="anonymous" />
            )
          }
          return t('common.noPhoto')
        },
      },
    },
  ]
}
