import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
} from '@material-ui/core'

import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'

export default function CreateOperatorConstructorDialog({
  open,
  onClose,
  onCreated,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [nDsp, setNDsp] = useState('')
  const [communities, setCommunities] = useState([])

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setName('')
    setNDsp('')
    setCommunities([])
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.length === 0) {
      setError('name_required')
      return
    }

    if (nDsp.length === 0) {
      setError('nDsp_required')
      return
    }

    if (communities.length === 0) {
      setError('communities_required')
      return
    }

    setLoading(true)
    const communitiesObj = communities.reduce((acc, community) => {
      if (community.id < 0) {
        acc.new.push(community.title)
      } else {
        acc.selection.push(community.id)
      }
      return acc
    }, { new: [], selection: [] })
    dataProvider.operator.post(name, undefined, nDsp, communitiesObj)
      .then(() => {
        dataProvider.cache.reset()
        showNotification('success', t('create-operator-dialog.success'))
        onCreated()
      }).catch(() => {
        showNotification('error', t('errors.api-error'))
      }).then(() => resetState())
  }
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-operator-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            autoFocus
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-operator-dialog.name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'nDsp_required'}
            helperText={t('common.required')}
            id="n-dsp"
            label={t('create-operator-dialog.n-dsp')}
            fullWidth
            value={nDsp}
            onChange={(event) => setNDsp(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setCommunities}
            value={communities}
            label={t('create-operator-dialog.communities')}
            resource="/communities/autocomplete"
            error={error === 'communities_required'}
            helperText={t('common.required')}
            multiple
            required
            canDelete
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value) {
                setCommunities(communities.concat({
                  id: (communities.length + 1) * -1,
                  title: (e.target.value || '').trim(),
                }))
              }
            }}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
