import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
} from '@material-ui/core'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

export default function CreateVehicleTypeDialog({ open, onClose, onCreated }) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setBrand('')
    setModel('')
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (brand.length === 0) {
      setError('brand_required')
      return
    }

    if (model.length === 0) {
      setError('model_required')
      return
    }

    const data = {
      brand,
      model,
    }
    setLoading(true)
    dataProvider.create('vehicle-type', { data }).then(() => {
      dataProvider.cache.reset()
      showNotification('success', t('create-vehicle-type-dialog.success'))
      onCreated()
    }).catch(() => {
      showNotification('error', t('errors.api-error'))
    }).then(() => resetState())
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-vehicle-type-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            autoFocus
            required
            error={error === 'brand_required'}
            helperText={t('common.required')}
            id="brand"
            label={t('create-vehicle-type-dialog.brand')}
            fullWidth
            value={brand}
            onChange={(event) => setBrand(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'model_required'}
            helperText={t('common.required')}
            id="model"
            label={t('create-vehicle-type-dialog.model')}
            fullWidth
            value={model}
            onChange={(event) => setModel(event.target.value)}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
