const Permissions = Object.freeze({
  PAGE_VEHICLES_TYPE_ROOT: 'page-vehicles-type:root',
  PAGE_CONTROLS_ROOT: 'page-controls:root',
  PAGE_VEHICLES_ROOT: 'page-vehicles:root',
  PAGE_OPERATORS_ROOT: 'page-operators:root',
  PAGE_USERS_ROOT: 'page-users:root',
  APPLICATION_ACCESS: 'application-access',
})

const permissionTree = {
  [Permissions.PAGE_VEHICLES_TYPE_ROOT]: [],
  [Permissions.PAGE_CONTROLS_ROOT]: [],
  [Permissions.PAGE_VEHICLES_ROOT]: [],
  [Permissions.PAGE_OPERATORS_ROOT]: [],
  [Permissions.PAGE_USERS_ROOT]: [],
  [Permissions.APPLICATION_ACCESS]: [],
}

function translate(permission) {
  return permission // TODO: translate when/if this project needs this feature
}

export {
  Permissions, translate, permissionTree,
}
