import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'

import './i18n'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import Themes from './themes'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { LayoutProvider } from './context/LayoutContext'
import { UserProvider } from './context/UserContext'
import { ApiDataProvider } from './context/DataApiContext'
import Loading from './components/Loading'
import { NotificationsProvider } from './context/NotificationsContext'
import { ConfirmationDialogProvider } from './context/ConfirmationDialogContext'

import 'moment/locale/fr'

moment.locale('fr')

ReactDOM.render(
  <LayoutProvider>
    <Suspense fallback={<Loading />}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
        <NotificationsProvider>
          <UserProvider>
            <ApiDataProvider>
              <ThemeProvider theme={Themes.default}>
                <ConfirmationDialogProvider>
                  <CssBaseline />
                  <App />
                </ConfirmationDialogProvider>
              </ThemeProvider>
            </ApiDataProvider>
          </UserProvider>
        </NotificationsProvider>
      </MuiPickersUtilsProvider>
    </Suspense>
  </LayoutProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
