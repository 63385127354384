import React from 'react'

// components
import {
  Button,
} from '@material-ui/core'

export default function generate({
  t, classes, meId, onModify, onDelete,
}) {
  return [
    {
      name: 'firstName',
      label: t('common.firstName'),
    },
    {
      name: 'lastName',
      label: t('common.lastName'),
    },
    {
      name: 'email',
      label: t('common.email'),
      options: {
        sort: false,
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val !== meId) {
            return (
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onDelete(tableMeta.rowIndex)}
                color="primary"
              >
                {t('common.delete')}
              </Button>
            )
          }
          return ''
        },
      },
    },
  ]
}
