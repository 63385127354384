import React from 'react'

// components
import {
  Button,
} from '@material-ui/core'

export default function generate({
  t, classes, onModify,
}) {
  return [
    {
      name: 'name',
      label: t('operators-list.name-table-header'),
    },
    {
      name: 'nDsp',
      label: t('operators-list.n-dsp-table-header'),
      options: {
        sort: true,
      },
    },
    {
      name: 'communities',
      label: t('operators-list.communities-header'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val && Array.isArray(val)) {
            return (
              <>
                {val.map((community) => (
                  <div key={community.id}>{community.name}</div>
                ))}
              </>
            )
          }
          return ''
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
  ]
}
