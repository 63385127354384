import React, { useState, useEffect } from 'react'
import {
  Button,
  TextField,
  Grid,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

// styles
import useStyles from './styles'
import BaseDialog from '../../../BaseDialog'

export default function ModifyUserDialog({
  title,
  open,
  onClose,
  onModified,
  onOpenEditPasswordDialog,
  enablePasswordChange,
  userToModify,
  modifyMe,
}) {
  const classes = useStyles()

  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setFirstName(userToModify.firstName)
    setLastName(userToModify.lastName)
    setEmail(userToModify.email)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (firstName.length === 0) {
      setError('firstName_required')
      return
    }

    if (lastName.length === 0) {
      setError('lastName_required')
      return
    }

    setLoading(true)
    const userId = modifyMe ? 'me' : userToModify.id
    const data = {
      firstName,
      lastName,
    }
    dataProvider.update('users', { data, id: userId }).then(() => {
      dataProvider.cache.reset()
      showNotification('success', t('modify-user-dialog.success'))
      onModified()
    }).catch(() => {
      showNotification('error', t('errors.api-error'))
    }).then(() => resetState())
  }

  useEffect(() => {
    if (userToModify) {
      setFirstName(userToModify.firstName)
      setLastName(userToModify.lastName)
      setEmail(userToModify.email)
    }
  }, [userToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
      openButtonTitle={t('common.modify')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            id="firstName"
            label={t('common.firstName')}
            error={error === 'firstName_required'}
            helperText={t('common.required')}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            id="lastName"
            label={t('common.lastName')}
            error={error === 'lastName_required'}
            helperText={t('common.required')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item>
          <TextField
            id="email"
            label={t('common.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            fullWidth
            disabled
          />
        </Grid>
        {enablePasswordChange && (
          <Grid item>
            <Button
              onClick={onOpenEditPasswordDialog}
              color="primary"
              className={classes.changePasswordButton}
            >
              {t('common.change-password')}
            </Button>
          </Grid>
        )}
      </Grid>

    </BaseDialog>
  )
}
