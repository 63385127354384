import React from 'react'
import {
  Route,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

// components
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

// pages
import Error from '../pages/error'

// context
import { useLayoutState } from '../context/LayoutContext'
import { defaultRoute } from '../context/UserContext'
import VehicleTypesList from '../pages/vehicle-types-list'
import VehicleList from '../pages/vehicle-list'
import ControlsList from '../pages/controls-list'
import UsersList from '../pages/users-list'
import ControlsDetail from '../pages/controls-detail'
import OperatorsList from '../pages/operators-list'
import DStatePolling from '../components/DState'

function Layout({ history }) {
  const classes = useStyles()

  // global
  const layoutState = useLayoutState()

  return (
    <div className={classes.root}>
      <>
        <Header history={history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route
              exact
              path="/"
              render={() => <Redirect to={defaultRoute()} />}
            />
            <Route path="/vehicle-types" component={VehicleTypesList} exact />
            <Route path="/controls" component={ControlsList} exact />
            <Route path="/dstate-polling/:stateId" component={DStatePolling} />
            <Route path="/controls/:id" component={ControlsDetail} />
            <Route path="/vehicles" component={VehicleList} />
            <Route path="/operators" component={OperatorsList} />
            <Route path="/users" component={UsersList} exact />
            <Route component={Error} />
          </Switch>
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
