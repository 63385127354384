import { createMuiTheme } from '@material-ui/core'
import defaultTheme from './default'

const overrides = {
  typography: {
    h1: {
      fontWeight: '300',
      fontSize: '96px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: '300',
      fontSize: '60px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h3: {
      fontWeight: 'normal',
      fontSize: '48px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h4: {
      fontWeight: 'normal',
      fontSize: '34px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 'normal',
      fontSize: '24px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    h6: {
      fontWeight: '500',
      fontSize: '18px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.15px',
    },
    body1: {
      fontWeight: 'normal',
      fontSize: '18px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.56',
      letterSpacing: '0.15px',
    },
    body2: {
      fontWeight: 'normal',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.43',
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: 'normal',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: 'normal',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.71',
      letterSpacing: 'normal',
    },
    button: {
      fontWeight: '500',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.75px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: '12px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.33',
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: 'normal',
      fontSize: '10px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.6',
      letterSpacing: '1.5px',
    },
  },
}

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
}
