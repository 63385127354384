import emailResult from './email-result'

function getItems(controlId, groupFormat, httpClient) {
  let url = `/control/${controlId}/items`
  if (typeof groupFormat !== 'undefined') {
    url += `?groupFormat=${groupFormat ? 'true': 'false'}`
  }
  return httpClient({
    url,
    method: 'GET',
  })
}

export default {
  emailResult,
  getItems,
}
