import axios from 'axios'
import config from '../config'

const axiosRefreshClient = axios.create({
  baseURL: `${config.apiBaseUrl}/su/auth`,
})

const logout = () => {
  localStorage.clear()
  window.location = '/login'
}

const shouldIntercept = (error) => {
  try {
    return error.response.status === 401
  } catch (e) {
    return false
  }
}

const setTokenData = (tokenData = {}) => {
  localStorage.setItem('tokens', JSON.stringify({ token: tokenData.token, refreshToken: tokenData.refreshToken }))
}

const inflateTokens = () => {
  const savedTokens = localStorage.getItem('tokens')
  if (savedTokens) {
    try {
      const tokens = JSON.parse(savedTokens)
      if (tokens && tokens.token && tokens.refreshToken) {
        return tokens
      }
      // eslint-disable-next-line no-empty
    } catch (err) { }
  }
  return null
}

const handleTokenRefresh = () => {
  const { refreshToken } = inflateTokens()
  return new Promise((resolve, reject) => {
    axiosRefreshClient.post('refresh', { refreshToken })
      .then(({ data }) => {
        const tokenData = {
          token: data.token,
          refreshToken: data.refreshToken,
        }
        resolve(tokenData)
      })
      .catch((err) => {
        reject(err)
        logout()
      })
  })
}

const attachTokenToRequest = (request, token) => {
  request.headers.Authorization = `Bearer ${token}`
}

export default (axiosClient, customOptions = {}) => {
  let isRefreshing = false
  let failedQueue = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
    ...customOptions,
  }
  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const interceptor = (error) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }

    // eslint-disable-next-line no-underscore-dangle
    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      return new Promise(((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })).then((token) => {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._queued = true
        options.attachTokenToRequest(originalRequest, token)
        return axiosClient.request(originalRequest)
        // eslint-disable-next-line max-len
      }).catch(() => Promise.reject(error), // Ignore refresh token request's "err" and return actual "error" for the original request
      )
    }

    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh.call(options.handleTokenRefresh)
        .then((tokenData) => {
          options.setTokenData(tokenData, axiosClient)
          options.attachTokenToRequest(originalRequest, tokenData.token)
          processQueue(null, tokenData.token)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}
