import React from 'react'

import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

const Table = ({
  id, columns, data, page, sort, onRowClick, onChangePage, onColumnSortChange,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const sortOrder = sort
    ? { name: sort.field, direction: sort.direction.toLowerCase() }
    : {}
  return (
    data && (
      <MUIDataTable
        key={id}
        data={data.items}
        columns={columns}
        options={{
          enableNestedDataAccess: '.',
          page,
          filter: false,
          print: false,
          search: false,
          viewColumns: false,
          download: false,
          selectableRows: 'none',
          rowsPerPage: data.range.limit,
          rowsPerPageOptions: [],
          count: data.itemCount,
          serverSide: true,
          sortOrder,
          onChangePage,
          onColumnSortChange,
          onRowClick,
          textLabels: {
            body: {
              noMatch: t('tables.body.noMatch'),
              toolTip: t('tables.body.toolTip'),
            },
            pagination: {
              next: t('tables.pagination.next'),
              previous: t('tables.pagination.previous'),
              rowsPerPage: t('tables.pagination.rowsPerPage'),
              displayRows: t('tables.pagination.displayRows'),
            },
            toolbar: {
              search: t('tables.toolbar.search'),
              downloadCsv: t('tables.toolbar.downloadCsv'),
              print: t('tables.toolbar.print'),
              viewColumns: t('tables.toolbar.viewColumns'),
              filterTable: t('tables.toolbar.filterTable'),
            },
            filter: {
              all: t('tables.filter.all'),
              title: t('tables.filter.title'),
              reset: t('tables.filter.reset'),
            },
            viewColumns: {
              title: t('tables.viewColumns.title'),
              titleAria: t('tables.viewColumns.titleAria'),
            },
            selectedRows: {
              text: t('tables.selectedRows.text'),
              delete: t('tables.selectedRows.delete'),
              deleteAria: t('tables.selectedRows.deleteAria'),
            },
          },
        }}
        className={classes.table}

      />
    )
  )
}

export default Table
