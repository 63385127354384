import React from 'react'
import {
  CircularProgress, Grid,
} from '@material-ui/core'

// styles
import useStyles from './styles'

export default function Loading() {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item xs={3}>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}
