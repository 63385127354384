import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Button,
} from '@material-ui/core'
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons'
import classNames from 'classnames'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext'
import {
  useUserDispatch, signOut, useUserState, refreshUser,
} from '../../context/UserContext'

import logo from '../../assets/logo.png'
import { ReactComponent as LogoutIcon } from '../../assets/Icons_Logout.svg'
import ModifyUserDialog from '../Users/components/ModifyUserDialog'
import ModifyUserPasswordDialog from '../Users/components/ModifyUserPasswordDialog'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'

export default function Header(props) {
  const classes = useStyles()

  // global
  const layoutState = useLayoutState()
  const layoutDispatch = useLayoutDispatch()
  const userDispatch = useUserDispatch()
  const { user } = useUserState()

  const { t } = useTranslation()

  // local
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)
  const [editUserPasswordOpen, setEditPasswordDialogOpen] = useState(false)
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const signOutWithConfirmation = () => showConfirmationDialog(
    t('common.confirm-logout'),
    () => signOut(userDispatch, props.history),
  )

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <ModifyUserDialog
        open={editUserDialogOpen}
        onClose={() => setEditUserDialogOpen(false)}
        onOpenEditPasswordDialog={() => {
          setEditPasswordDialogOpen(true)
        }}
        userToModify={user}
        onModified={() => refreshUser(userDispatch)}
        title={t('common.modify-me')}
        enablePasswordChange
        modifyMe
      />
      <ModifyUserPasswordDialog
        open={editUserPasswordOpen}
        onClose={() => {
          setEditPasswordDialogOpen(false)
        }}
      />
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Hidden smDown>
          <Button onClick={() => props.history.push('/')}>
            <img src={logo} alt="logo" className={classes.logo} />
          </Button>
        </Hidden>
        <div className={classes.grow} />
        <Button
          color="primary"
          className={classes.userNameButton}
          onClick={() => setEditUserDialogOpen(true)}
        >
          {user.fullName}
        </Button>

        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={signOutWithConfirmation}
        >
          <LogoutIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
