function autocompleteEmails(httpClient) {
  return httpClient({
    url: '/email-autocomplete',
    method: 'GET',
  })
}

export default {
  autocompleteEmails,
}
