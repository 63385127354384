function isValidEmail(email) {
  const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  return pattern.test(email)
}

function isValidPassword(password) {
  if (password.length < 8) {
    return false
  }
  // at least 1 capital letter or 1 symbol
  const pattern = new RegExp(/[^a-z0-9]+/g)
  return pattern.test(password)
}

function isValidNItem(nItem) {
  const nItemRegex = new RegExp(/^\w?\w?\w-\w\w?\w?$/)
  return nItemRegex.test(nItem)
}

function isValidNParc(nParc) {
  const nParcRegex = new RegExp(/^\d{5,6}$/)
  return nParcRegex.test(nParc)
}
function isValidKmsCount(nParc) {
  const nParcRegex = new RegExp(/^[0-9]*$/)
  return nParcRegex.test(nParc)
}

function isValidPlate() {
  return true
}

function isValidNChassis(nChassis) {
  const nChassisRegex = new RegExp(/[A-Z0-9]{17}/g)
  return nChassisRegex.test(nChassis)
}

export default {
  isValidEmail,
  isValidPassword,
  isValidNItem,
  isValidNParc,
  isValidNChassis,
  isValidPlate,
  isValidKmsCount,
}
