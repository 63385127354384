import React from 'react'
import {
  SvgIcon,
} from '@material-ui/core'

const ControlIcon = () => (
  <SvgIcon>
    <path d="M13 9H1v2h12V9zm0-4H1v2h12V5zM1 15h8v-2H1v2zm19.5-4.5L22 12l-6.99 7-4.51-4.5L12 13l3.01 3 5.49-5.5z" />
  </SvgIcon>
)

export default ControlIcon
