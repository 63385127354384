import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
} from '@material-ui/core'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'
import validators from '../../../../utils/validators'

const dateFormat = 'DD/MM/YYYY'
const lastDayOfYearDate = moment.utc(new Date(new Date().getFullYear() - 1, 11, 31))
  .format(dateFormat)

export default function ModifyVehicleDialog({
  open,
  onClose,
  onCreated,
  vehicleToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [vehicleType, setVehicleType] = useState(null)
  const [plate, setPlate] = useState('')
  const [firstCirculationDate, setFirstCirculationDate] = useState(new Date())
  const [kmsCount, setKmsCount] = useState('')
  const [nParc, setNParc] = useState('')
  const [operateur, setOperateur] = useState(null)
  const [community, setCommunity] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setVehicleType(null)
    setPlate('')
    setFirstCirculationDate(new Date())
    setKmsCount('')
    setNParc('')
    setOperateur(null)
    setCommunity(null)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    setError(null)

    if (vehicleType === null) {
      setError('vehicleType_required')
      return
    }

    if (plate.length === 0) {
      setError('plate_required')
      return
    }
    if (!validators.isValidPlate(plate)) {
      setError('plate_invalid')
      return
    }

    if (firstCirculationDate === null) {
      setError('first_circulation_date_required')
      return
    }

    if (kmsCount.length === 0) {
      setError('kmsCount_required')
      return
    }
    if (!validators.isValidKmsCount(kmsCount)) {
      setError('kmsCount_invalid')
      return
    }

    if (operateur === null) {
      setError('operateur_required')
      return
    }

    if (community === null) {
      setError('community_required')
      return
    }

    const data = {}
    if (vehicleToModify.vehicleTypeId !== vehicleType.id) {
      data.vehicleTypeId = vehicleType.id
    }
    if (vehicleToModify.plate !== plate) {
      data.plate = plate
    }
    if (vehicleToModify.firstCirculationDate !== firstCirculationDate.toISOString()) {
      data.firstCirculationDate = firstCirculationDate.toISOString()
    }
    if (vehicleToModify.kmsCount !== kmsCount) {
      data.kmsCount = kmsCount
    }
    if (vehicleToModify.operatorId !== operateur.id) {
      data.operatorId = operateur.id
    }
    if (vehicleToModify.localCommunityId !== community.id) {
      data.communityId = community.id
    }
    const sanitezedNParc = (nParc || '').trim().length === 0 ? undefined : (nParc || '').trim()
    if (vehicleToModify.nParc !== sanitezedNParc) {
      data.nParc = sanitezedNParc
    }
    setLoading(true)
    dataProvider.update('vehicle', { data, id: vehicleToModify.id }).then(() => {
      dataProvider.cache.reset()
      setLoading(false)
      showNotification('success', t('modify-vehicle-dialog.success'))
      onCreated()
      resetState()
    }).catch((err) => {
      setLoading(false)
      if (err.response.data.validationErrors && err.response.data.validationErrors.length > 0) {
        showNotification('error', err.response.data.validationErrors[0].errorMessage)
        setError(`${err.response.data.validationErrors[0].field}_invalid`)
      } else {
        showNotification('error', t('errors.api-error'))
      }
    })
  }

  useEffect(() => {
    if (vehicleToModify) {
      setVehicleType(vehicleToModify.vehicleType)
      setPlate(vehicleToModify.plate)
      setFirstCirculationDate(new Date(vehicleToModify.firstCirculationDate))
      setKmsCount(vehicleToModify.kmsCount)
      setNParc(vehicleToModify.nParc)
      if (vehicleToModify.operator) {
        setOperateur({ id: vehicleToModify.operatorId, title: vehicleToModify.operator.name })
      }
      if (vehicleToModify.localCommunity) {
        setCommunity({
          id: vehicleToModify.localCommunityId, title: vehicleToModify.localCommunity.name,
        })
      }
    }
  }, [vehicleToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-vehicle-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            error={error === 'vehicleType_required'}
            onChange={setVehicleType}
            label={t('common.vehicleType')}
            resource="vehicle-type/autocomplete"
            value={vehicleType}
            canDelete
          />
        </Grid>
        <Grid item>
          <TextField
            error={error === 'plate_required' || error === 'plate_invalid'}
            helperText={error && (error === 'plate_invalid'
              ? t('errors.not-valid')
              : t('common.required'))}
            id="plate"
            label={t('common.plate')}
            fullWidth
            value={plate}
            onChange={(event) => setPlate(event.target.value)}
          />
        </Grid>
        <Grid item>
          <DatePicker
            id="firstCirculationDate"
            label={t('common.firstCirculationDate')}
            value={firstCirculationDate}
            format={dateFormat}
            openTo="year"
            fullWidth
            cancelLabel={t('common.cancel')}
            helperText={t('common.required')}
            required
            onChange={setFirstCirculationDate}
          />
        </Grid>
        <Grid item>
          <TextField
            error={error === 'kmsCount_required' || error === 'kmsCount_invalid'}
            helperText={
              error && (error === 'kmsCount_invalid'
                ? t('errors.not-valid')
                : t('common.required'))
            }
            id="kmsCount"
            label={t('common.kmsCountAtDate', { date: lastDayOfYearDate })}
            fullWidth
            value={kmsCount}
            onChange={(event) => setKmsCount(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="nParc"
            label={t('common.nParc')}
            fullWidth
            value={nParc || ''}
            onChange={(event) => setNParc(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'operateur_required'}
            onChange={(val) => {
              setOperateur(val)
              setCommunity(null)
            }}
            label={t('common.operateur')}
            resource="operators/autocomplete"
            value={operateur}
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'community_required'}
            onChange={setCommunity}
            label={t('common.localCommunity')}
            resource="communities/autocomplete"
            query={{ operatorId: operateur ? operateur.id : undefined }}
            value={community}
            disabled={!operateur}
            required
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
