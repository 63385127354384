import React from 'react'
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  useMediaQuery,
  useTheme,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'

import useStyles from './styles'

import { ReactComponent as CloseIcon } from '../../assets/Icons_Close.svg'

export default function BaseDialog({
  open,
  onClose,
  onConfirm,
  title,
  children,
  hideCloseButton,
  loading,
  openButtonTitle,
  closeButtonTitle,
  hideActions,
  confirmDisabled = false,
  classes: overrideClasses,
  width = 'sm',
}) {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  const openButtonTitleTranslation = openButtonTitle || t('common.confirm')
  const closeButtonTitleTranslation = closeButtonTitle || t('common.cancel')

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} classes={overrideClasses} maxWidth={width} fullWidth={!!width}>
      { !hideCloseButton && (
        <DialogTitle disableTypography className={classes.titleContainer}>
          <Grid container alignItems="center" justify="flex-start">

            <Grid item>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <Typography variant="h6">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
      )}

      <DialogContent classes={overrideClasses}>
        {loading
          ? (
            <Grid container justify="space-around">
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )
          : (
            <>
              {children}
              {!hideActions && (
                <DialogActions className={hideCloseButton
                  ? classes.actionsContainerCenter
                  : classes.actionsContainerEnd}
                >
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="outlined"
                  >
                    {closeButtonTitleTranslation}
                  </Button>
                  <Button
                    onClick={onConfirm}
                    color="primary"
                    variant="contained"
                    disabled={confirmDisabled}
                  >
                    {openButtonTitleTranslation}
                  </Button>
                </DialogActions>
              )}
            </>
          )}

      </DialogContent>
    </Dialog>
  )
}
