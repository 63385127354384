import React from 'react'

// components
import {
  Button,
  Typography,
} from '@material-ui/core'
import dateFnsFormat from 'date-fns/format'

export default function generate({
  t,
  classes,
  onModify,
}) {
  return [
    {
      name: 'vehicleType.title',
      label: t('common.vehicleType'),
      options: {
        sortThirdClickReset: true,
      },
    },
    {
      name: 'plate',
      label: t('common.plate'),
    },
    {
      name: 'firstCirculationDate',
      label: t('common.firstCirculationDate'),
      options: {
        customBodyRender: (val) => {
          const formatedDate = dateFnsFormat(
            Date.parse(val),
            'dd/MM/yyyy',
          )
          return (
            <Typography>
              {formatedDate}
            </Typography>
          )
        },
      },
    },
    {
      name: 'kmsCountAtLastDate',
      label: t('common.kmsCountCurrent'),
    },
    {
      name: 'operator.name',
      label: t('common.operateur'),
    },
    {
      name: 'nParc',
      label: t('common.nParc'),
    },
    {
      name: 'operator.nDsp',
      label: t('common.nDsp'),
    },
    {
      name: 'localCommunity.name',
      label: t('common.localCommunity'),
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
