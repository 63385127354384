import React from 'react'

// components
import {
  Button,
} from '@material-ui/core'

export default function generate({
  t, classes, onDeactivate, onModify, onSee,
}) {
  return [
    {
      name: 'brand',
      label: t('vehicle-types-list.brand'),
    },
    {
      name: 'model',
      label: t('vehicle-types-list.model'),
      options: {
        sort: false,
      }
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onModify(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.modify')}
          </Button>
        ),
      },
    },
    {
      name: 'active',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onDeactivate(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.deactivate')}
          </Button>
        ),
      },
    },
  ]
}
