import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import {
  Grid,
  IconButton,
} from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'

export default function OurDatePicker({
  id,
  value,
  onChange,
  label,
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="center" alignItems="flex-end">
        <DatePicker
          id={id}
          variant="inline"
          disableToolbar
          format="dd, MMM yyyy"
          margin="normal"
          label={label}
          value={value}
          onChange={onChange}
          autoOk
          fullWidth
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <>
                {
                value && (
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => onChange(null)}
                >
                  <ClearIcon />
                </IconButton>
                )
                }
              </>
            ),
          }}
        />

      </Grid>
    </MuiPickersUtilsProvider>
  )
}
