import React, { useState, useEffect } from 'react'
import {
  Dialog,
  useMediaQuery,
  useTheme,
  DialogContent,
  Grid,
  IconButton,
} from '@material-ui/core'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import useStyles from './styles'

import { ReactComponent as CloseIcon } from '../../assets/Icons_Close.svg'

export default function PhotoGaleryDialog({
  open, onClose, images,
}) {
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedImage, setSelectedImage] = useState()
  // eslint-disable-next-line
  const [selectedImageIndex, setSelectedImageIndex] = useState()
  const nextImage = () => {
    let index = selectedImageIndex + 1
    if (index >= images.length) {
      index = 0
    }
    setSelectedImage(images[index])
    setSelectedImageIndex(index)
  }
  const previousImage = () => {
    let index = selectedImageIndex - 1
    if (index < 0) {
      index = images.length - 1
    }
    setSelectedImage(images[index])
    setSelectedImageIndex(index)
  }
  useEffect(() => {
    if (images && images.length > 0) {
      setSelectedImage(images[0])
      setSelectedImageIndex(0)
    }
    // eslint-disable-next-line
  }, [images])
  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogContent>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justify="center">
          { images.length > 1 && (
            <Grid item xs={1}>
              <IconButton
                onClick={previousImage}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={10}>
            <img
              src={selectedImage}
              alt="dialog"
              className={classes.image}
              crossOrigin="anonymous"
            />
          </Grid>
          { images.length > 1 && (
            <Grid item xs={1}>
              <IconButton
                onClick={nextImage}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
