import React, { useState } from 'react'
import {
  TextField,
  Grid,
} from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'

// styles
// import useStyles from './styles'
import BaseDialog from '../../../BaseDialog'
import validators from '../../../../utils/validators'

export default function CreateUserDialog({
  title,
  open,
  onClose,
  onCreated,
}) {
  // const classes = useStyles()

  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError(null)
    setFirstName('')
    setLastName('')
    setEmail('')
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (firstName.length === 0) {
      setError('firstName_required')
      return
    }

    if (lastName.length === 0) {
      setError('lastName_required')
      return
    }

    if (email.length === 0) {
      setError('email_required')
      return
    }

    if (!validators.isValidEmail(email)) {
      setError('email_invalid')
      return
    }

    setLoading(true)

    const data = {
      firstName,
      lastName,
      email,
    }
    dataProvider.create('users', { data }).then(() => {
      dataProvider.cache.reset()
      showNotification('success', t('create-user-dialog.success'))
      onCreated()
    }).catch(() => {
      showNotification('error', t('errors.api-error'))
    }).then(() => resetState())
  }

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            autoFocus
            id="firstName"
            label={t('common.firstName')}
            error={error === 'firstName_required'}
            helperText={t('common.required')}
            type="text"
            value={firstName}
            fullWidth
            required
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="lastName"
            label={t('common.lastName')}
            error={error === 'lastName_required'}
            helperText={t('common.required')}
            value={lastName}
            type="text"
            fullWidth
            required
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="email"
            label={t('common.email')}
            error={error === 'email_required' || error === 'email_invalid'}
            helperText={error === 'email_invalid' ? t('errors.not-valid') : t('common.required')}
            value={email}
            type="text"
            fullWidth
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
