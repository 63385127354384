import React from 'react'

// components
import {
  Button,
  Typography,
  Grid,
} from '@material-ui/core'
import dateFnsFormat from 'date-fns/format'

export default function generate({
  t, classes, tableData, onControlClick,
}) {
  return [
    {
      name: 'controlDate',
      label: t('controls-list.controlDate'),
      options: {
        customBodyRender: (val) => {
          const formatedDate = dateFnsFormat(
            Date.parse(val),
            'dd/MM/yyyy',
          )
          return (
            <Typography>
              {formatedDate}
            </Typography>
          )
        },
      },
    },
    {
      name: 'vehicle.operator.nDsp',
      label: t('common.nDsp'),
    },
    {
      name: 'vehicle.operator.name',
      label: t('common.operateur'),
    },
    {
      name: 'vehicle.localCommunity.name',
      label: t('common.localCommunity'),
    },
    {
      name: 'vehicle.nParc',
      label: t('common.nParc'),
    },
    {
      name: 'vehicle.plate',
      label: t('common.plate'),
    },
    {
      name: 'vehicle.vehicleType.title',
      label: t('common.vehicleType'),
      options: {
        sortThirdClickReset: true,
      },
    },
    {
      name: 'id',
      label: t('common.actions'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Grid container direction="column">
            <Grid item>
              <Button
                className={classes.linkButton}
                variant="text"
                onClick={() => onControlClick(tableData.items[tableMeta.rowIndex].id)}
                color="primary"
              >
                {t('common.see')}
              </Button>
            </Grid>
          </Grid>
        ),
      },
    },
  ]
}
