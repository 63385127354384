import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import CreateVehicleDialog from '../../components/Vehicle/components/CreateVehicleDialog'
import ModifyVehicleDialog from '../../components/Vehicle/components/ModifyVehicleDialog'
import generateColumns from './columns'

export default function VehicleList() {
  const { t } = useTranslation()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [openCreateVehicleDialog, setOpenCreateVehicleDialog] = useState(false)
  const [openModifyVehicleDialog, setOpenModifyVehicleDialog] = useState(false)

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0, sort: null, filters: null,
  })
  const [tableData, setTableData] = useState(null)

  const [vehicleToModify, setVehicleToModify] = useState(null)

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ ...tableState })
    } else {
      setTableState({
        page: 0, sort: null, filters: null,
      })
    }
  }

  useEffect(() => {
    dataProvider.getList(
      'vehicle',
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const tableColumns = generateColumns({
    t,
    classes,
    onModify: (idx) => {
      setVehicleToModify(tableData.items[idx])
      setOpenModifyVehicleDialog(true)
    },
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateVehicleDialog
        open={openCreateVehicleDialog}
        onClose={() => setOpenCreateVehicleDialog(false)}
        onCreated={() => {
          refreshData(true)
          setOpenCreateVehicleDialog(false)
        }}
      />
      <ModifyVehicleDialog
        open={openModifyVehicleDialog}
        onClose={() => {
          setOpenModifyVehicleDialog(false)
          setVehicleToModify(null)
        }}
        onCreated={() => {
          refreshData(true)
          setOpenModifyVehicleDialog(false)
        }}
        vehicleToModify={vehicleToModify}
      />
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('vehicle-list.title')} />
        </Grid>
        <Grid item container sm={8} justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateVehicleDialog(true)}
          >
            {t('create-vehicle-dialog.title')}
          </Button>
        </Grid>
      </Grid>
      <Table
        id="vehicle-list"
        columns={tableColumns}
        data={tableData}
        page={tableState.page}
        sort={tableState.sort}
        onChangePage={(currentPage) => {
          setTableState({ ...tableState, page: currentPage })
        }}
        onColumnSortChange={(changedColumn, direction) => {
          const newSort = {
            field: changedColumn,
            direction: direction.toUpperCase(),
          }
          setTableState({ ...tableState, sort: newSort })
        }}
      />
    </>
  )
}
