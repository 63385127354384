import React from 'react'
import {
  Paper,
  Typography,
  Button,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

// styles
import useStyles from './styles'

export default function Error() {
  const classes = useStyles()
  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Typography
        variant="h1"
        color="primary"
        className={classnames(classes.textRow, classes.errorCode)}
      >
        404
      </Typography>
      <Typography variant="h5" color="primary" className={classes.textRow}>
        Oups. Il semble que la page que vous recherchez n&apos;existe plus.
      </Typography>
      <Typography
        variant="h6"
        color="text"
        colorBrightness="secondary"
        className={classnames(classes.textRow, classes.safetyText)}
      >
        Mais nous sommes ici pour vous ramener en sécurité
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        size="large"
        className={classes.backButton}
      >
        Retour à la page d’accueil
      </Button>
    </Paper>
  )
}
