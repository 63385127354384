import React from 'react'
import { Button, Typography } from '@material-ui/core'

// styles
import useStyles from './styles'

export default function PageTitle({ button, title, classes: classesOverrides }) {
  const classes = useStyles({ classes: classesOverrides })

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h4">
        {title}
      </Typography>
      {button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {button}
        </Button>
      )}
    </div>
  )
}
