import React, { useState } from 'react'
import {
  useParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DStateLabelComponent } from 'dstate-ui'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

export default function DStatePolling() {
  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const { stateId } = useParams()
  const [dState, setDState] = useState({})

  const performPolling = () => {
    dataProvider.get(
      `dstate-polling/${stateId}`,
      {},
    ).then((response) => {
      setDState(response.data)
    }).catch(() => showNotification('error', t('errors.api-error')))
  }

  return (
    <DStateLabelComponent
      onPolling={performPolling}
      dState={dState}
      label={t('common.loading')}
    />
  )
}
