import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import DatePicker from '../../components/DatePicker'

import { useQueryState } from '../../utils/query-state'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import generateColumns from './columns'

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export default function ControlsList({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  
  const query = useQuery()
  const [startDate, setStartDate] = useQueryState({
    paramName: 'startDate',
    history,
    query,
    fromQuery: useCallback((value) => (value ? new Date(value) : null), []),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [endDate, setEndDate] = useQueryState({
    paramName: 'endDate',
    history,
    query,
    fromQuery: useCallback((value) => (value ? new Date(value) : new Date()), []),
    toQuery: useCallback((value) => value.toISOString(), []),
  })
  const [vehicleType, setVehicleType] = useQueryState({ paramName: 'vehicleType', history, query })
  const [plate, setPlate] = useQueryState({ paramName: 'plate', history, query })
  const [community, setCommunity] = useQueryState({ paramName: 'community', history, query })
  const [nDsp, setNDsp] = useQueryState({ paramName: 'nDsp', history, query })
  const [operator, setOperator] = useQueryState({ paramName: 'operator', history, query })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      nDsp: nDsp ? nDsp.title : null,
      operatorId: operator ? operator.id : null,
      communityId: community ? community.id : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      plate: plate ? plate.title : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    return filtersObj
  }, [startDate, endDate, nDsp, operator, community, vehicleType, plate])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({ paramName: 'sort', history, query })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page', defaultValue: 0, history, query,
  })
  const [tableState, setTableState] = useState({
    page: tablePage, sort: tableSort, filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  const resource = 'control'

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = () => {
    setTableState({ ...tableState, page: 0, filters: computedFilters })
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setNDsp(null)
    setOperator(null)
    setCommunity(null)
    setVehicleType(null)
    setPlate(null)
    setTableState({ ...tableState, page: 0, filters: null })
  }

  const generateReport = () => {
    const filtersObj = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      nDsp: nDsp ? nDsp.title : null,
      operatorId: operator ? operator.id : null,
      communityId: community ? community.id : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      plate: plate ? plate.title : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    dataProvider.getList(
      'exports/control/signalments',
      {
        filters: filtersObj,
      },
    ).then((response) => {
      if (response.data.url) {
        window.open(response.data.url, '_blank')
      }
    }).catch(() => showNotification('error', t('errors.api-error')))
  }

  const onControlClick = (id) => {
    history.push(`/controls/${id}`)
  }

  const tableColumns = generateColumns({
    t,
    classes,
    tableData,
    onControlClick,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={6}>
          <PageTitle title={t('controls-list.title')} />
        </Grid>
      </Grid>
      <Grid container item className={classes.filtersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid item container alignItems="center" justify="space-between" spacing={1}>
          <Grid container item xs={12} md={6} alignItems="flex-end" className={classes.alignItemsBaseline}>
            <Grid item xs={12} md={5}>
              <DatePicker
                id="startDate"
                value={startDate}
                onChange={setStartDate}
                label={t('common.startDate')}
              />
            </Grid>
            <Grid item md={1}>
              <Typography variant="subtitle1" align="center">
                Au
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <DatePicker
                id="endDate"
                value={endDate}
                onChange={setEndDate}
                label={t('common.endDate')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setNDsp}
              label={t('common.nDsp')}
              resource="operators/n-dsp-autocomplete"
              value={nDsp}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setOperator}
              label={t('common.operateur')}
              resource="operators/autocomplete"
              value={operator}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justify="flex-start" spacing={1}>
          <Grid item xs={12} md={4}>
            <AutoCompleteInput
              onChange={setCommunity}
              label={t('common.localCommunity')}
              resource="communities/autocomplete"
              value={community}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutoCompleteInput
              onChange={setVehicleType}
              label={t('common.vehicleType')}
              resource="vehicle-type/autocomplete"
              value={vehicleType}
              query={{ includeDisabled: true }}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AutoCompleteInput
              onChange={setPlate}
              label={t('common.plate')}
              resource="vehicle/license-plate-autocomplete"
              value={plate}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end" className={classes.actionsContainer}>
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="primary"
              variant="outlined"
              onClick={clearFilters}
              fullWidth
            >
              {t('common.clean')}
            </Button>
          </Grid>
          <Grid item xs={12} md={2} xl={1}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={updateFilters}
            >
              {t('common.search')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-end" justifyContent="flex-end" direction="column">
        <Grid item xs={14} md={4} xl={2}>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={generateReport}
          >
            {t('controls-list.generateReportCount')}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer} xs={12}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>

    </Grid>
  )
}
