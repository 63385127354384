import { useTranslation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

export default function AutoCompleteInput({
  onChange,
  label,
  resource,
  value,
  defaultValue,
  required,
  error,
  query,
  refreshOnInputChange,
  multiple,
  getOptionLabel,
  disabled,
  freeSolo,
  onInputChange,
  canDelete,
  onKeyDown,
}) {
  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [optionsReceived, setOptionsReceived] = useState({
    state: false,
    query: null,
  })

  let timerID
  const WAIT_INTERVAL = 1000

  const getOptions = (text) => {
    dataProvider.get(`${resource}`, { query: { q: text, ...query } })
      .then((response) => setOptions(response.data))
      .catch(() => showNotification('error', t('errors.api-error')))
      .then(() => {
        setLoading(false)
        setOptionsReceived({
          state: true,
          query,
        })
      })
  }

  useEffect(() => {
    if (optionsReceived.state && query !== optionsReceived.query) {
      setOptions([])
      setOptionsReceived({
        state: false,
        query,
      })
    }
  }, [query, optionsReceived.state, optionsReceived.query])

  const getChipProps = () => {
    if (canDelete === false) {
      return {
        deleteIcon: null,
        onDelete: null,
      }
    }
    return null
  }

  return (
    <Autocomplete
      id={`autocomple-${resource}`}
      loading={loading}
      loadingText={t('common.loading')}
      options={options}
      disabled={disabled}
      freeSolo={freeSolo}
      getOptionLabel={(option) => {
        if (!option) {
          return ''
        }
        if (getOptionLabel) {
          return getOptionLabel(option)
        }
        return option.title || ''
      }}
      noOptionsText={t('common.noOptions')}
      value={value}
      defaultValue={defaultValue}
      multiple={multiple === true}
      disableClearable={canDelete === false}
      clearOnEscape={canDelete}
      ChipProps={getChipProps()}
      onChange={(event, newValue, reason) => {
        if (canDelete === true) {
          onChange(newValue)
        } else if (canDelete === false && reason !== 'remove-option') {
          onChange(newValue)
        } else {
          onChange(value)
        }
      }}
      renderInput={(params) => (
        <TextField
          error={error}
          required={required}
          helperText={required ? t('common.required') : ''}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          onKeyDown={(e) => {
            if (onKeyDown) {
              onKeyDown(e)
            }
          }}
        />
      )}
      onInputChange={(event, newTextValue) => {
        if (refreshOnInputChange) {
          setLoading(true)
          clearTimeout(timerID)
          timerID = setTimeout(() => {
            getOptions(newTextValue)
          }, WAIT_INTERVAL)
        }
        if (onInputChange) {
          onInputChange(newTextValue)
        }
      }}
      onOpen={() => {
        if (!optionsReceived.state && !loading) {
          setLoading(true)
          clearTimeout(timerID)
          getOptions()
        }
      }}
      getOptionSelected={(option, val) => (option.id ? option.id === val.id : option === val)}
    />
  )
}
