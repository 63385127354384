import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'

import {
  useParams,
} from 'react-router-dom'

import dateFnsFormat from 'date-fns/format'
import Loading from '../../components/Loading'
import useStyles from './styles'
import Table from '../../components/Table'
import generateItemsColumns from './columns-items'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'

import PhotoGaleryDialog from '../../components/PhotoGaleryDialog'

export default function ControlsDetail() {
  const { t } = useTranslation()
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { id } = useParams()

  const [control, setControl] = useState({})
  const [loading, setLoading] = useState(true)
  const [photos, setPhotos] = useState([])
  const [openPhotoGalleryDialog, setOpenPhotoGalleryDialog] = useState(false)

  const [loadingItems, setLoadingItems] = useState(true)
  const [itemsTableState] = useState({
    page: 0,
    sort: null,
  })
  const [itemsTableData, setItemsTableData] = useState(null)

  useEffect(() => {
    dataProvider.getOne('control', { id })
      .then((response) => {
        setControl(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    dataProvider.control.getItems(
      id,
      true,
    ).then((response) => {
      setItemsTableData(response.data)
      setLoadingItems(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [itemsTableState, id])

  const onShowPhotos = (photosToShow) => {
    setPhotos(photosToShow)
    setOpenPhotoGalleryDialog(true)
  }

  const itemsTableColumns = generateItemsColumns({
    t,
    classes,
    onShowPhotos,
  })

  if (loading || loadingItems) {
    return <Loading />
  }
  const {
    submittedByUser,
    controlDate,
    reportState,
    reportUrl,
    vehicle: {
      operator: {
        nDsp: operatorNDsp,
      },
      vehicleType,
      plate,
      firstCirculationDate,
    },
    vehiclePhotoUrl: assessmentPhotoUrl,
    vehicleKmsCount: assessmentKms,
    vehicleTechnicalControlDate: assessmentValidityDate,
  } = control

  return (
    <Grid container direction="column">
      <PhotoGaleryDialog
        open={openPhotoGalleryDialog}
        onClose={() => setOpenPhotoGalleryDialog(false)}
        images={photos}
      />
      <Grid container className={classes.firstSection} direction="column">
        <Grid
          container
          className={[classes.firstSectionSecondSubSection, classes.sectionBottomBorder].join(' ')}
          direction="column"
          spacing={2}
        >
          <Grid container item spacing={2} xs={4}>
            <Grid item>
              <Typography variant="h6">
                {t('common.date')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {dateFnsFormat(
                  Date.parse(controlDate),
                  'dd/MM/yyyy H:mm',
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid container justify="space-between">
              <Grid container item spacing={2} xs={5}>
                <Grid item>
                  <Typography variant="h6">{t('common.controlBy')}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.textBlack}>
                    {submittedByUser.fullName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={5} spacing={2} alignItems="center" justify="flex-end">
                {reportState === 1 && reportUrl && (
                  <Grid item>
                    <Button
                      href={reportUrl}
                      target="_blank"
                      fullWidth
                      color="primary"
                      variant="contained"
                    >
                      {t('controls-detail.download-control-report')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={[classes.firstSectionSecondSubSection, classes.sectionBottomBorder].join(' ')}
        >
          <Grid item className={classes.marginBottom}>
            <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle].join(' ')}>
              {t('common.vehicle')}
            </Typography>
          </Grid>
          <Grid container justify="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.vehicleType')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {vehicleType.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('controls-detail.n-dsp')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {operatorNDsp}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.plate')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {plate}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid container item spacing={2} xs={4}>
              <Grid item>
                <Typography variant="h6">
                  {t('common.firstCirculationDate')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.textBlack}>
                  {dateFnsFormat(
                    Date.parse(firstCirculationDate),
                    'dd/MM/yyyy',
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={[classes.firstSectionSecondSubSection, classes.sectionBottomBorder].join(' ')}
        >
          <Grid item>
            <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle].join(' ')}>
              {t('controls-detail.assessment-title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          className={classes.firstSectionSecondSubSection}
        >
          <Grid container item justify="space-between" alignItems="center" className={classes.assessmentItem}>
            <Grid item>
              <Typography variant="h6">
                {t('controls-detail.assessment-photo-title')}
              </Typography>
            </Grid>
            <Grid item className={classes.assessmentPhoto}>
              { /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */ }
              <img alt="vehicle" className={classes.assessmentPhoto} src={assessmentPhotoUrl} onClick={() => onShowPhotos([assessmentPhotoUrl])} onKeyDown={() => onShowPhotos([assessmentPhotoUrl])} crossOrigin="anonymous" />
            </Grid>
          </Grid>
          <Grid container item justify="space-between" alignItems="center" className={classes.assessmentItem}>
            <Grid item>
              <Typography variant="h6">
                {t('controls-detail.assessment-validity-date-title')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {dateFnsFormat(
                  Date.parse(assessmentValidityDate),
                  'dd/MM/yyyy',
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" alignItems="center" className={classes.assessmentItem}>
            <Grid item>
              <Typography variant="h6">
                {t('controls-detail.assessment-kms-title')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" className={classes.textBlack}>
                {t('controls-detail.assessment-kms-format', { kms: assessmentKms })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(itemsTableData).map((parentParentItemName) => (
        <Grid container direction="column" key={parentParentItemName}>
          <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle, classes.parentParentItemTitle].join(' ')}>
            {parentParentItemName}
          </Typography>

          <Grid container direction="column">
            {Object.keys(itemsTableData[parentParentItemName]).map((parentItemName, index) => {
              const data = {
                items: itemsTableData[parentParentItemName][parentItemName],
                range: {},
              }
              return (
                <Grid container className={classes.secondSection} direction="column" key={parentItemName}>
                  <Grid item className={classes.secondSectionFirstSubSection}>
                    <Grid container justify="space-between" alignItems="flex-end">
                      <Grid item>
                        <Typography variant="h6" className={[classes.textBlack, classes.sectionTitle].join(' ')}>
                          {parentItemName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.tableContainer}>
                    <Table
                      id={`table-${index}`}
                      columns={itemsTableColumns}
                      data={data}
                    />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
