import React from 'react'

import authApi from '../api/authApi'

const UserStateContext = React.createContext()
const UserDispatchContext = React.createContext()

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        ...state, user: action.payload.user, isAuthenticated: true,
      }
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false }
    case 'UPDATE_USER':
      return { ...state, user: action.payload.user }
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider({ children }) {
  const response = authApi.fetchUser.read()
  const user = response ? response.data : null
  const userPermissions = {
    chassis: { read: true, write: true },
    vehicleTypes: { read: true, write: true },
    users: { read: true, write: true },
    controls: { read: true, write: true },
  }

  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: user != null,
    user,
    userPermissions,
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState() {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(null)
  setIsLoading(true)
  authApi.loginUser(login, password).then((res) => {
    const responsePayload = res.data
    localStorage.setItem('tokens', JSON.stringify({ token: responsePayload.token, refreshToken: responsePayload.refreshToken }))
    setIsLoading(false)
    // set user
    dispatch({ type: 'LOGIN_SUCCESS', payload: { user: responsePayload.user } })
    history.push('/')
  }).catch(() => {
    setError('errors.wrongPassword')
    setIsLoading(false)
  })
}

function signOut(dispatch, history) {
  localStorage.removeItem('tokens')
  dispatch({ type: 'SIGN_OUT_SUCCESS' })
  history.push('/login')
}

const refreshUser = (dispatch) => {
  authApi.fetchUserRequest().then(
    (refreshResponse) => dispatch({ type: 'UPDATE_USER', payload: { user: refreshResponse.data } }),
  )
}

function hasPermission(permission, user) {
  const permissions = (user || {}).permissions || []
  return permissions.some((p) => p === permission)
}

function defaultRoute() {
  return '/vehicle-types'
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  refreshUser,
  hasPermission,
  defaultRoute,
}
