import React from 'react'
import {
  Typography,
} from '@material-ui/core'
import BaseDialog from '../components/BaseDialog'

const ConfirmationDialogStateContext = React.createContext()
const ConfirmationDialogDispatchContext = React.createContext()

function confirmationDialogReducer(state, action) {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        isOpened: true,
        onConfirm: action.payload.onConfirm,
        confirmButtonTitle: action.payload.confirmButtonTitle,
        message: action.payload.message,
      }
    case 'CLOSE':
      return {
        ...state, onConfirm: null, message: '', isOpened: false,
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ConfirmationDialogProvider({ children }) {
  const [state, dispatch] = React.useReducer(confirmationDialogReducer, {
    isOpened: false,
  })
  const showConfirmationDialog = (message, onConfirm, confirmButtonTitle) => {
    dispatch({ type: 'OPEN', payload: { message, onConfirm, confirmButtonTitle } })
  }
  return (
    <ConfirmationDialogStateContext.Provider value={{ state, showConfirmationDialog }}>
      <ConfirmationDialogDispatchContext.Provider value={dispatch}>
        <BaseDialog
          hideCloseButton
          open={state.isOpened}
          openButtonTitle={state.confirmButtonTitle}
          onConfirm={() => {
            state.onConfirm()
            dispatch({ type: 'CLOSE', payload: { } })
          }}
          onClose={() => {
            dispatch({ type: 'CLOSE', payload: { } })
          }}
        >
          <Typography variant="body1">
            {state.message}
          </Typography>
        </BaseDialog>
        {children}
      </ConfirmationDialogDispatchContext.Provider>
    </ConfirmationDialogStateContext.Provider>
  )
}

function useConfirmationDialogProvider() {
  const context = React.useContext(ConfirmationDialogStateContext)
  if (context === undefined) {
    throw new Error('useNotificationsState must be used within a NotificationsProvider')
  }
  return context
}

function useConfirmationDialogDispatch() {
  const context = React.useContext(ConfirmationDialogDispatchContext)
  if (context === undefined) {
    throw new Error('useNotificationsDispatch must be used within a NotificationsProvider')
  }
  return context
}

export {
  ConfirmationDialogProvider, useConfirmationDialogProvider, useConfirmationDialogDispatch,
}
